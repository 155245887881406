
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background-color:#f4f6f9 !important;}
.error-snackbar {
    background-color: #f44336; /* Red color for errors */
    color: white;
  }
  
  td a{color: #399e8f !important;}

  .breadcrumbnew {
    margin-bottom: 15px;
  
    h4 {
      font-size: 16px;
      margin: 0px;
  
      @media screen and (max-width: 1600px) {
        font-size: 14px;
      }
  
      span {
        cursor: pointer;
        transition: .2s;
  
        &:hover {
          text-decoration: underline;
          color: #080050;
        }
      }
    }
  }

  .Cstm_wrapper_area .main-sidebar {
    height: calc(100% - 0px) !important; /* Add a space before and after the operator */
    position: fixed !important;
}

  .Cstm_wrapper_area .main-sidebar .sidebar {
    /* overflow-y: auto; */
    height: 100%;
    height: 100% !important;
    max-height: 550px;
    /* overflow-y: auto; */
    overflow-y: auto !important;
  }
  
  .Cstm_wrapper_area .main-sidebar .sidebar::-webkit-scrollbar {
    width: 6px;
  }

  .Cstm_wrapper_area .main-sidebar .sidebar nav .nav{
    overflow-y: auto;
    height: calc(100% - 58px);
    display: flex;
    flex-wrap: nowrap;
  }

  .table-container{min-height:100vh; margin:10px;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #ffffff; /* Clean white background */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */}

  .font-normal{font-weight:500 !important;}

  .filter-element{
    display: flex;
        justify-content: center;
        padding:5px;
        gap:10px;
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom:10px;
    color: #399e8f;
   
  }

  .header-container h3{ font-weight: bold !important; margin:0 !important;}


  @media only screen and (max-width:900px) {
   
    .filter-element{display:block;}

    .content-header{padding:0 !important;}

    .custom-table {overflow: scroll;
      display: block;}

      .pagination-container{overflow: scroll;}
    }



    body.sidebar-open .main-header {
      transition: margin-left 0.3s ease-in-out;
      margin-left: 228px !important;
    }